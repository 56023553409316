import React from "react";

class Clients extends React.Component {
    state = {  } 
    render() { 
        return (
            <div class="client-logo-area rel z-1 pt-130 rpt-100 pb-60">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-xl-12">
                            <div class="section-title text-center pt-5 mb-65 wow fadeInUp delay-0-2s">
                                <h6>I’ve <span>1253+ Global Clients</span> & lot’s of Project Complete</h6>
                            </div>
                        </div>
                    </div>
                    <div class="client-logo-wrap">
                        <a class="client-logo-item wow fadeInUp delay-0-2s" href="contact.html">
                            <img src="images/client-logos/client-logo1.png" alt="Client Logo" />
                        </a>
                        <a class="client-logo-item wow fadeInUp delay-0-3s" href="contact.html">
                            <img src="images/client-logos/client-logo2.png" alt="Client Logo" />
                        </a>
                        <a class="client-logo-item wow fadeInUp delay-0-4s" href="contact.html">
                            <img src="images/client-logos/client-logo3.png" alt="Client Logo" />
                        </a>
                        <a class="client-logo-item wow fadeInUp delay-0-5s" href="contact.html">
                            <img src="images/client-logos/client-logo4.png" alt="Client Logo" />
                        </a>
                        <a class="client-logo-item wow fadeInUp delay-0-6s" href="contact.html">
                            <img src="images/client-logos/client-logo5.png" alt="Client Logo" />
                        </a>
                        <a class="client-logo-item wow fadeInUp delay-0-2s" href="contact.html">
                            <img src="images/client-logos/client-logo6.png" alt="Client Logo" />
                        </a>
                        <a class="client-logo-item wow fadeInUp delay-0-3s" href="contact.html">
                            <img src="images/client-logos/client-logo7.png" alt="Client Logo" />
                        </a>
                        <a class="client-logo-item wow fadeInUp delay-0-4s" href="contact.html">
                            <img src="images/client-logos/client-logo8.png" alt="Client Logo" />
                        </a>
                        <a class="client-logo-item wow fadeInUp delay-0-5s" href="contact.html">
                            <img src="images/client-logos/client-logo9.png" alt="Client Logo" />
                        </a>
                        <a class="client-logo-item wow fadeInUp delay-0-6s" href="contact.html">
                            <img src="images/client-logos/client-logo10.png" alt="Client Logo" />
                        </a>
                    </div>
                </div>
                <div class="bg-lines">
                <span></span><span></span>
                <span></span><span></span>
                <span></span><span></span>
                <span></span><span></span>
                <span></span><span></span>
                </div>
            </div>
        );
    }
}
 
export default Clients;