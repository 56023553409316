import React from "react";


class Portfolio extends React.Component {
    state = {  } 
    render() { 
        return (
            <section id="portfolio" class="projects-area pt-130 rpt-100 pb-100 rpb-70 rel z-1">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-xl-12">
                            <div class="section-title text-center mb-60 wow fadeInUp delay-0-2s">
                                <span class="sub-title mb-15">Latest Works</span>
                                <h2>Explore My Popular <span>Projects</span></h2>
                            </div>
                        </div>
                    </div>
                    <div class="row align-items-center pb-25">
                        <div class="col-lg-6">
                            <div class="project-image wow fadeInLeft delay-0-2s">
                                <img src="images/projects/project1.png" alt="Project" />
                            </div>
                        </div>
                        <div class="col-xl-5 col-lg-6">
                            <div class="project-content wow fadeInRight delay-0-2s">
                                <span class="sub-title">Mobile Application</span>
                                <h2><a href="https://play.google.com/store/apps/details?id=tech.digitrade.darana" target="_blank">Darana</a></h2>
                                <p>Digitizing your product tracking process.</p>
                                <a href="https://play.google.com/store/apps/details?id=tech.digitrade.darana" target="_blank" class="details-btn"><i class="far fa-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>
                    <div class="row align-items-center pb-25">
                        <div class="col-lg-6 order-lg-2">
                            <div class="project-image wow fadeInLeft delay-0-2s">
                                <img src="images/projects/project2.jpg" alt="Project" />
                            </div>
                        </div>
                        <div class="col-xl-5 col-lg-6 ms-auto">
                            <div class="project-content wow fadeInRight delay-0-2s">
                                <span class="sub-title">Mobile Application</span>
                                <h2><a href="https://play.google.com/store/apps/details?id=com.kolwaz.book" target="_blank">Kolwaz</a></h2>
                                <p>Purchase and sale books .</p>
                                <a href="https://play.google.com/store/apps/details?id=com.kolwaz.book" target="_blank" class="details-btn"><i class="far fa-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>
                    <div class="row align-items-center pb-25">
                        <div class="col-lg-6">
                            <div class="project-image wow fadeInLeft delay-0-2s">
                                <img src="images/projects/project3.png" alt="Project" />
                            </div>
                        </div>
                        <div class="col-xl-5 col-lg-6">
                            <div class="project-content wow fadeInRight delay-0-2s">
                                <span class="sub-title">Flutter Package</span>
                                <h2><a href="https://pub.dev/packages/circle_card" target="_blank">circle_card</a></h2>
                                <p>Flutter package to help developer build build circular card simply.</p>
                                <a href="https://pub.dev/packages/circle_card" target="_blank" class="details-btn"><i class="far fa-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>
                    {/* <div class="project-btn text-center wow fadeInUp delay-0-2s">
                        <a href="projects.html" class="theme-btn">View More Projects <i class="far fa-angle-right"></i></a>
                    </div> */}
                </div>
                <div class="bg-lines">
                <span></span><span></span>
                <span></span><span></span>
                <span></span><span></span>
                <span></span><span></span>
                <span></span><span></span>
                </div>
            </section>
        );
    }
}
 
export default Portfolio;