import React from 'react';
import {Routes, Route} from 'react-router-dom';

import LandingPage from "./pages/LandingPage";
import BlogDetails from "./pages/BlogDetails";
import Loader from "./pages/Loader";
import './App.css';

class App extends React.Component{

  /*componentDidMount() {
    const script = document.createElement("script");
    //script.src = "../js/main.js";
    script.src = "../js/script.js";
    script.async = true;
    document.body.appendChild(script);
  }*/

  render(){
    return (
      <Routes>
        <Route exact path='/' element={<LandingPage/>}></Route>
        <Route exact path='/blog-details' element={<BlogDetails/>}></Route>
      </Routes>
    );
  }
}

export default App;
