import React from "react";
import { getFirestore, collection, setDoc, doc } from "firebase/firestore";

import app from '../../firebase';

const db = getFirestore(app);
const validateEmail = (email) => {
    return email.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
}


class Contact extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            adding: false,
            name: "",
            email: "",
            phone: "",
            subject: "",
            message: "",
        };
        this.addMessage = this.addMessage.bind(this);
    }

    clearForm = () => {
        document.getElementById("contactForm").reset();
    }

    async addMessage(){
        if(!this.state.name || !this.state.email || !this.state.subject || !this.state.message){
            alert("Veuillez remplir tout les champs")
        }else if(this.state.email.indexOf("@") === -1){
            alert("Veuillez entrer une adresse mail valide");
        }else{
            this.setState({
                adding: true,
            })
            let time = new Date().getTime();
            let reference = time.toString(36).toUpperCase();
            setDoc(doc(db, "messages", reference), {
                "reference": reference,
                "name": this.state.name,
                "email": this.state.email,
                "phone": this.state.phone,
                "subject": this.state.subject,
                "message": this.state.message
            }).then(() => {
                this.clearForm();
                this.setState({
                    adding: false,
                });
                alert("Vous recevrez un message !");
            }).catch((e) => {
                console.log("** ERROR **", e);
                this.setState({
                    adding: false,
                });
            });
        }
    }

    render() { 
        return (
            <section id="contact" class="contact-area pt-95 pb-130 rpt-70 rpb-100 rel z-1">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4">
                            <div class="contact-content-part pt-5 rpt-0 rmb-55 wow fadeInUp delay-0-2s">
                                <div class="section-title mb-40">
                                    <span class="sub-title mb-15">Get In Touch</span>
                                    <h2>Let’s Talk For your <span>Next Projects</span></h2>
                                    <p>"Even though i am exhausted, when i am in contact with the public, i feel myself growing wings." - Grégory Lemarchal</p>
                                </div>
                                <ul class="list-style-two">
                                    <li>Customized Solutions</li>
                                    <li>Efficient Problem-Solver</li>
                                    <li>Collaborative Approach</li>
                                    <li>Commitment to Quality</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-8">
                            <div class="contact-form contact-form-wrap form-style-one wow fadeInUp delay-0-4s">
                                <form id="contactForm" class="contactForm" name="contactForm">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="name">Full Name</label>
                                                <input type="text" id="name" name="name" className="form-control" placeholder="John Doe" required data-error="Please enter your Name" 
                                                    onChange={(e) => {
                                                        this.setState({
                                                            name: e.target.value
                                                        });
                                                    }} 
                                                />
                                                <label for="name" class="for-icon"><i class="far fa-user"></i></label>
                                                <div class="help-block with-errors"></div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="email">Email Address</label>
                                                <input type="email" id="email" name="email" class="form-control" placeholder="johndoe@me.com" required data-error="Please enter your Email" 
                                                    onChange={(e) => {
                                                        this.setState({
                                                            email: e.target.value
                                                        });
                                                    }}
                                                />
                                                <label for="email" class="for-icon"><i class="far fa-envelope"></i></label>
                                                <div class="help-block with-errors"></div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="phone_number">Phone Number</label>
                                                <input type="text" id="phone_number" name="phone_number" class="form-control" placeholder="+0 (00) 000 00" required data-error="Please enter your Phone Number" 
                                                    onChange={(e) => {
                                                        this.setState({
                                                            phone: e.target.value
                                                        });
                                                    }}
                                                />
                                                <label for="phone_number" class="for-icon"><i class="far fa-phone"></i></label>
                                                <div class="help-block with-errors"></div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="subject">Subject</label>
                                                <input type="text" id="subject" name="subject" class="form-control" placeholder="Subject" required data-error="Please enter your Subject" 
                                                    onChange={(e) => {
                                                        this.setState({
                                                            subject: e.target.value
                                                        });
                                                    }}
                                                />
                                                <label for="subject" class="for-icon"><i class="far fa-text"></i></label>
                                                <div class="help-block with-errors"></div>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label for="message">Message</label>
                                                <textarea name="message" id="message" class="form-control" rows="4" placeholder="write message" required data-error="Please enter your Message" 
                                                    onChange={(e) => {
                                                        this.setState({
                                                            message: e.target.value
                                                        });
                                                    }}
                                                ></textarea>
                                                <div class="help-block with-errors"></div>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group mb-0">
                                                <button type="submit" class="theme-btn" onClick={this.addMessage} disabled={this.state.adding}>Send Us Message <i class="far fa-angle-right"></i></button>
                                                <div id="msgSubmit" class="hidden"></div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bg-lines">
                <span></span><span></span>
                <span></span><span></span>
                <span></span><span></span>
                <span></span><span></span>
                <span></span><span></span>
                </div>
            </section>
        );
    }
}
 
export default Contact;