import { initializeApp } from 'firebase/app';

//const settings = {timestampsInSnapshots: true};

const firebaseConfig = {
    apiKey: "AIzaSyDriY3Q3IqnJQxj53-1i274Fxkl6QjqlCQ",
    authDomain: "easy-school-c3659.firebaseapp.com",
    projectId: "easy-school-c3659",
    storageBucket: "easy-school-c3659.appspot.com",
    messagingSenderId: "269437086245",
    appId: "1:269437086245:web:0d95999e5e34fc61606dcc"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default app;
