import React from "react";

class Blog extends React.Component {
    state = {  } 
    render() { 
        return (
            <section id="blog" class="blog-area rel z-1">
                <div class="for-bgc-black pt-130 pb-100 rpt-100 rpb-70">
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-xl-12">
                                <div class="section-title text-center mb-60 wow fadeInUp delay-0-2s">
                                    <span class="sub-title mb-15">News & Blog</span>
                                    <h2>Latest News & <span>Blog</span></h2>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="blog-item wow fadeInUp delay-0-2s">
                                    <div class="image">
                                        <img src="images/blog/blog1.png" alt="Blog" />
                                    </div>
                                    <div class="content">
                                        <div class="blog-meta mb-35">
                                            <a class="tag" href="https://pub.dev/packages/getx_test" target="_blank">Hive</a>
                                            <a class="tag" href="https://pub.dev/packages/hive" target="_blank">GetX</a>
                                        </div>
                                        <h5><a href="https://jocelinfowe.hashnode.dev/flutter-hive-and-getx" target="_blank">Learn how to use HIVE and GETX in Flutter by building a note app.</a></h5>
                                        <hr />
                                        <div class="blog-meta mt-35">
                                            <a class="date" href="#"><i class="far fa-calendar-alt"></i>November 28, 2021</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*  <div class="col-lg-6">
                                <div class="blog-item wow fadeInUp delay-0-2s">
                                    <div class="image">
                                        <img src="images/blog/blog2.png" alt="Blog" />
                                    </div>
                                    <div class="content">
                                        <div class="blog-meta mb-35">
                                            <a class="tag" href="blog.html">Design</a>
                                            <a class="tag" href="blog.html">Figma</a>
                                        </div>
                                        <h5><a href="blog-details.html">Keyboard-Only Suppor Assistive Technology</a></h5>
                                        <hr />
                                        <div class="blog-meta mt-35">
                                            <a class="date" href="#"><i class="far fa-calendar-alt"></i> September 25, 2023</a>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div class="bg-lines">
                <span></span><span></span>
                <span></span><span></span>
                <span></span><span></span>
                <span></span><span></span>
                <span></span><span></span>
                </div>
            </section>
        );
    }
}
 
export default Blog;