import React from "react";

class Services extends React.Component {
    state = {  }
    render() { 
        return ( 
            <section id="services" class="services-area pt-130 rpt-100 pb-100 rpb-70 rel z-1">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-xl-6 col-lg-8">
                            <div class="section-title text-center mb-60 wow fadeInUp delay-0-2s">
                                <span class="sub-title mb-15">Popular Services</span>
                                <h2>My <span>Special Service</span> For your Business Development</h2>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="service-item wow fadeInUp delay-0-2s">
                                <div class="number">01.</div>
                                <div class="content">
                                    <h4>API Development</h4>
                                    <p>Dignissimos ducimus blanditiis praesen</p>
                                </div>
                                <a href="#" class="details-btn"><i class="fas fa-arrow-right"></i></a>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="service-item wow fadeInUp delay-0-4s">
                                <div class="number">02.</div>
                                <div class="content">
                                    <h4>Web Application</h4>
                                    <p>Dignissimos ducimus blanditiis praesen</p>
                                </div>
                                <a href="#" class="details-btn"><i class="fas fa-arrow-right"></i></a>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="service-item wow fadeInUp delay-0-2s">
                                <div class="number">03.</div>
                                <div class="content">
                                    <h4>Mobile Application</h4>
                                    <p>Dignissimos ducimus blanditiis praesen</p>
                                </div>
                                <a href="#" class="details-btn"><i class="fas fa-arrow-right"></i></a>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="service-item wow fadeInUp delay-0-4s">
                                <div class="number">06.</div>
                                <div class="content">
                                    <h4>Tests</h4>
                                    <p>Dignissimos ducimus blanditiis praesen</p>
                                </div>
                                <a href="#" class="details-btn"><i class="fas fa-arrow-right"></i></a>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="service-item wow fadeInUp delay-0-4s">
                                <div class="number">04.</div>
                                <div class="content">
                                    <h4>CI / CD</h4>
                                    <p>Dignissimos ducimus blanditiis praesen</p>
                                </div>
                                <a href="#" class="details-btn"><i class="fas fa-arrow-right"></i></a>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="service-item wow fadeInUp delay-0-2s">
                                <div class="number">05.</div>
                                <div class="content">
                                    <h4>Database Management</h4>
                                    <p>Dignissimos ducimus blanditiis praesen</p>
                                </div>
                                <a href="#" class="details-btn"><i class="fas fa-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bg-lines">
                <span></span><span></span>
                <span></span><span></span>
                <span></span><span></span>
                <span></span><span></span>
                <span></span><span></span>
                </div>
            </section>
         );
    }
}
 
export default Services;