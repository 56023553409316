import React from "react";

class Skills extends React.Component {
    state = {  } 
    render() { 
        return (
            <section id="skills" class="skill-area rel z-1">
                <div class="for-bgc-black pt-130 rpt-100 pb-100 rpb-70">
                    <div class="container">
                        <div class="row gap-100">
                            <div class="col-lg-5">
                                <div class="skill-content-part rel z-2 rmb-55 wow fadeInUp delay-0-2s">
                                    <div class="section-title mb-40">
                                        <span class="sub-title mb-15">My Skills</span>
                                        <h2>Let’s Explore Popular <span>Skills & Tools</span></h2>
                                        <p>"Knowledge is acquired through experience, everything else is is just information" - Albert Einstein</p>
                                        <p>Experienced Backend Developer with a strong background in database management in Oracle, PostgreSQL. Skilled in developing efficient backend solutions using DotNet and proficient in handling SQL queries, database triggers, and performance tuning. Adept at integrating databases with frontend technologies like Flutter and React JS, ensuring seamless data operations and user interactions.</p>
                                    </div>
                                    {/* <a href="about.html" class="theme-btn">Learn More <i class="far fa-angle-right"></i></a> */}
                                </div>
                            </div>
                            <div class="col-lg-7">
                                <div class="skill-items-wrap">
                                    <div class="row">
                                        <div class="col-xl-3 col-lg-4 col-md-3 col-sm-4 col-6">
                                            <div class="skill-item wow fadeInUp delay-0-2s">
                                                <img src="images/skills/skill1.png" alt="Skill" />
                                                <h5>DotNet</h5>
                                                {/* <span class="percent">95%</span> */}
                                            </div>
                                        </div>
                                        <div class="col-xl-3 col-lg-4 col-md-3 col-sm-4 col-6">
                                            <div class="skill-item wow fadeInUp delay-0-3s">
                                                <img src="images/skills/skill2.png" alt="Skill" />
                                                <h5>SQL</h5>
                                            </div>
                                        </div>
                                        <div class="col-xl-3 col-lg-4 col-md-3 col-sm-4 col-6">
                                            <div class="skill-item wow fadeInUp delay-0-3s">
                                                <img src="images/skills/skill17.png" alt="Skill" />
                                                <h5>Oracle DB</h5>
                                            </div>
                                        </div>
                                        <div class="col-xl-3 col-lg-4 col-md-3 col-sm-4 col-6">
                                            <div class="skill-item wow fadeInUp delay-0-4s">
                                                <img src="images/skills/skill3.png" alt="Skill" />
                                                <h5>Keycloak</h5>
                                            </div>
                                        </div>
                                        <div class="col-xl-3 col-lg-4 col-md-3 col-sm-4 col-6">
                                            <div class="skill-item wow fadeInUp delay-0-5s">
                                                <img src="images/skills/skill4.png" alt="Skill" />
                                                <h5>GraphQL</h5>
                                            </div>
                                        </div>
                                        <div class="col-xl-3 col-lg-4 col-md-3 col-sm-4 col-6">
                                            <div class="skill-item wow fadeInUp delay-0-2s">
                                                <img src="images/skills/skill5.png" alt="Skill" />
                                                <h5>Kafka</h5>
                                            </div>
                                        </div>
                                        <div class="col-xl-3 col-lg-4 col-md-3 col-sm-4 col-6">
                                            <div class="skill-item wow fadeInUp delay-0-3s">
                                                <img src="images/skills/skill6.png" alt="Skill" />
                                                <h5>Mongo DB</h5>
                                            </div>
                                        </div>
                                        <div class="col-xl-3 col-lg-4 col-md-3 col-sm-4 col-6">
                                            <div class="skill-item wow fadeInUp delay-0-4s">
                                                <img src="images/skills/skill7.png" alt="Skill" />
                                                <h5>Docker</h5>
                                            </div>
                                        </div>
                                        <div class="col-xl-3 col-lg-4 col-md-3 col-sm-4 col-6">
                                            <div class="skill-item wow fadeInUp delay-0-5s">
                                                <img src="images/skills/skill8.png" alt="Skill" />
                                                <h5>Azure DevOps</h5>
                                            </div>
                                        </div>
                                        <div class="col-xl-3 col-lg-4 col-md-3 col-sm-4 col-6">
                                            <div class="skill-item wow fadeInUp delay-0-5s">
                                                <img src="images/skills/skill9.png" alt="Skill" />
                                                <h5>Flutter</h5>
                                            </div>
                                        </div>
                                        <div class="col-xl-3 col-lg-4 col-md-3 col-sm-4 col-6">
                                            <div class="skill-item wow fadeInUp delay-0-5s">
                                                <img src="images/skills/skill10.png" alt="Skill" />
                                                <h5>Firebase</h5>
                                            </div>
                                        </div>
                                        <div class="col-xl-3 col-lg-4 col-md-3 col-sm-4 col-6">
                                            <div class="skill-item wow fadeInUp delay-0-5s">
                                                <img src="images/skills/skill11.png" alt="Skill" />
                                                <h5>Node JS</h5>
                                            </div>
                                        </div>
                                        <div class="col-xl-3 col-lg-4 col-md-3 col-sm-4 col-6">
                                            <div class="skill-item wow fadeInUp delay-0-5s">
                                                <img src="images/skills/skill12.png" alt="Skill" />
                                                <h5>React JS</h5>
                                            </div>
                                        </div>
                                        <div class="col-xl-3 col-lg-4 col-md-3 col-sm-4 col-6">
                                            <div class="skill-item wow fadeInUp delay-0-5s">
                                                <img src="images/skills/skill13.png" alt="Skill" />
                                                <h5>Selenium</h5>
                                            </div>
                                        </div>
                                        <div class="col-xl-3 col-lg-4 col-md-3 col-sm-4 col-6">
                                            <div class="skill-item wow fadeInUp delay-0-5s">
                                                <img src="images/skills/skill14.png" alt="Skill" />
                                                <h5>X Unit</h5>
                                            </div>
                                        </div>
                                        <div class="col-xl-3 col-lg-4 col-md-3 col-sm-4 col-6">
                                            <div class="skill-item wow fadeInUp delay-0-5s">
                                                <img src="images/skills/skill15.png" alt="Skill" />
                                                <h5>SonarQube</h5>
                                            </div>
                                        </div>
                                        <div class="col-xl-3 col-lg-4 col-md-3 col-sm-4 col-6">
                                            <div class="skill-item wow fadeInUp delay-0-5s">
                                                <img src="images/skills/skill16.png" alt="Skill" />
                                                <h5>Postman</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bg-lines">
                <span></span><span></span>
                <span></span><span></span>
                <span></span><span></span>
                <span></span><span></span>
                <span></span><span></span>
                </div>
            </section>
        );
    }
}
 
export default Skills;