import React from "react";

class About extends React.Component {
    state = {  } 
    render() { 
        return (
            <section id="about" class="about-area rel z-1">
                <div class="for-bgc-black py-130 rpy-100">
                    <div class="container">
                        <div class="row gap-100 align-items-center">
                            <div class="col-lg-7">
                                <div class="about-content-part rel z-2 rmb-55">
                                    <div class="section-title mb-35 wow fadeInUp delay-0-2s">
                                        <span class="sub-title mb-15">About Me</span>
                                        <h2>I Make <span>Live Easier</span> Using Digital</h2>
                                        <p>"I am not in competition with anyone other than my self. My goal is to beat my last performance" - Celine Dion</p>
                                    </div>
                                    <ul class="list-style-one two-column wow fadeInUp delay-0-2s">
                                        <li>Application Development</li>
                                        <li>Financial Solutions Integration</li>
                                        <li>Databases</li>
                                        <li>Cloud Computing</li>
                                        <li>BlockChain</li>
                                        <li>Project Mangement</li>
                                    </ul>
                                    <div class="about-info-box mt-25 wow fadeInUp delay-0-2s">
                                        <div class="info-box-item">
                                            <i class="far fa-envelope"></i>
                                            <div class="content">
                                                <span>Email Me</span><br/>
                                                <a href="mailto:jocelinlaroch08@gmail.com">jocelinlaroch08@gmail.com</a>
                                            </div>
                                        </div>
                                        <div class="info-box-item">
                                            <i class="far fa-phone"></i>
                                            <div class="content">
                                                <span>WhatsApp</span><br />
                                                <a href="https://api.whatsapp.com/send?phone=237655287554" target="_blank">+237 655287554</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-5">
                                <div class="about-image-part wow fadeInUp delay-0-3s">
                                    <img src="images/about/about.jpg" alt="About Me" />
                                    <div class="about-btn btn-one wow fadeInRight delay-0-4s">
                                        <img src="images/about/btn-image1.png" alt="Image" />
                                        <h6>Optimizing Work Processes</h6>
                                        <i class="fas fa-arrow-right"></i>
                                    </div>
                                    <div class="about-btn btn-two wow fadeInRight delay-0-5s">
                                        <img src="images/about/btn-image1.png" alt="Image" />
                                        <h6>Innovate</h6>
                                        <i class="fas fa-arrow-right"></i>
                                    </div>
                                    <div class="dot-shape">
                                        <img src="images/shape/about-dot.png" alt="Shape" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bg-lines">
                <span></span><span></span>
                <span></span><span></span>
                <span></span><span></span>
                <span></span><span></span>
                <span></span><span></span>
                </div>
            </section>
        );
    }
}
 
export default About;