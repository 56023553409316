import React from "react";

import { getFirestore, collection, setDoc, doc } from "firebase/firestore";

import app from '../../firebase';

const db = getFirestore(app);
const validateEmail = (email) => {
    return email.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
}

class Footer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            adding: false,
            email: "",
        };
        this.subscribe = this.subscribe.bind(this);
    }

    clearForm = () => {
        document.getElementById("contactForm").reset();
    }

    async subscribe(){
        if(!this.state.email){
            alert("Veuillez entrer votre adresse")
        }else if(this.state.email.indexOf("@") === -1){
            alert("Veuillez entrer une adresse mail valide");
        }else{
            this.setState({
                adding: true,
            })
            let time = new Date().getTime();
            let reference = time.toString(36).toUpperCase();
            setDoc(doc(db, "subscriptions", reference), {
                "reference": reference,
                "email": this.state.email,
            }).then(() => {
                this.clearForm();
                this.setState({
                    adding: false,
                });
                alert("Merci pour votre souscription !");
            }).catch((e) => {
                console.log("** ERROR **", e);
                this.setState({
                    adding: false,
                });
            });
        }
    }

    render() { 
        return (
            <footer class="main-footer rel z-1">
                <div class="footer-top-wrap bgc-black pt-100 pb-75">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-2 col-md-12">
                                <div class="footer-widget widget_logo wow fadeInUp delay-0-2s">
                                    <div class="footer-logo">
                                        <a href="index.html"><img src="images/logos/logo.png" alt="Logo" /></a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-7 col-md-7">
                                <div class="footer-widget widget_nav_menu wow fadeInUp delay-0-4s">
                                    <h6 class="footer-title">Quick Link</h6>
                                    <ul>
                                        <li><a href="#home">Home</a></li>
                                        <li><a href="#services">Service</a></li>
                                        <li><a href="#portfolio">Projects</a></li>
                                        <li><a href="#skills">Skills</a></li>
                                        <li><a href="#blog">Blog</a></li>
                                    </ul>
                                    <h6 class="newsletter-title">Newsletter</h6>
                                </div>
                                <div class="footer-widget widget_newsletter wow fadeInUp delay-0-4s">
                                    <form id="contactForm">
                                        <label for="email-address"><i class="far fa-envelope"></i></label>
                                        <input id="email-address" type="email" placeholder="Email Address" required 
                                            onChange={(e) => {
                                                this.setState({
                                                    email: e.target.value
                                                });
                                            }}
                                        />
                                        <button onClick={this.subscribe} disabled={this.state.adding}>Register<i class="far fa-angle-right"></i></button>
                                    </form>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-5">
                                <div class="footer-widget widget_contact_info wow fadeInUp delay-0-6s">
                                    <h6 class="footer-title">Address</h6>
                                    <ul>
                                        <li><i class="far fa-map-marker-alt"></i>Douala, Cameroon</li>
                                        <li><i class="far fa-envelope"></i> <a href="mailto:jocelinLaroch08@gmail.com">jocelinLaroch08@gmail.com</a></li>
                                        <li><i class="far fa-phone"></i> <a href="callto:+237655287554">+237 6 55 28 75 54</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="footer-bottom pt-20 pb-5 rpt-25">
                    <div class="container">
                    <div class="row">
                        <div class="col-lg-6">
                                <div class="copyright-text">
                                    <p>Copyright @2023, <a href="index.html">JF</a> All Rights Reserved</p>
                                </div>
                        </div>
                        <div class="col-lg-6 text-lg-end">
                            <ul class="footer-bottom-nav">
                                <li><a href="https://www.linkedin.com/in/jocelin-fowe-01a99a177?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app" target="_blank">LinkedIn</a></li>
                                <li><a href="https://x.com/jocelinlaroch?t=9VXsfTIo3dxA4SnHDAqMDA&s=09" target="_blank">Twitter</a></li>
                            </ul>
                        </div>
                    </div>
                        <button class="scroll-top scroll-to-target" data-target="html"><span class="fas fa-angle-double-up"></span></button>
                    </div>
                    <div class="bg-lines">
                    <span></span><span></span>
                    <span></span><span></span>
                    <span></span><span></span>
                    <span></span><span></span>
                    <span></span><span></span>
                    </div>
                </div>
            </footer>
        );
    }
}
 
export default Footer;