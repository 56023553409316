import React from 'react';
import Header from '../sections/landing-page/Header';
import Home from '../sections/landing-page/Home';
import About from '../sections/landing-page/About';
import Resume from '../sections/landing-page/Resume';
import Services from '../sections/landing-page/Services';
import Skills from '../sections/landing-page/Skills';
import Portfolio from '../sections/landing-page/Portfolio';
import Testimonials from '../sections/landing-page/Testimonials';
import Pricing from '../sections/landing-page/Pricing';
import Contact from '../sections/landing-page/Contact';
import Blog from '../sections/landing-page/Blog';
import Clients from '../sections/landing-page/Clients';
import Footer from '../sections/landing-page/Footer';

class LandingPage extends React.Component {
    render() {
        return (
            <div className="home-one">
                <div className="page-wrapper">

                    {/*<div className="preloader"></div>*/}

                    <Header />

                    <div className="form-back-drop"></div>

                    <Home />

                    <About />

                    <Resume />

                    <Services />

                    <Skills />

                    <Portfolio />

                    {/* <Testimonials />

                    <Pricing /> */}

                    <Blog />

                    <Contact />

                    {/* <Clients /> */}

                    <Footer />

                </div>
            </div>
        );
    }
}

export default LandingPage;